import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconArrow } from "./../images/icon-arrow.svg";
import { ReactComponent as IconCamera } from "./../images/icon-camera.svg";

export default function InstructionsModal() {
  const { t } = useTranslation();
  /*<p>{t("Welcome to React")}</p> */
  return (
    <div className="instructions overlay">
      <div className="instructions-left">
        <div className="instructions-left-inner">
          <div className="instructions-text">
            <h1>{t("Mängujuhend")}</h1>
            <div dangerouslySetInnerHTML={{__html:t("juhend_html")}}></div>
          </div>
          <ul className="game-images game-images-sample">
              <li>
                <div className="game-images-image">
                  <IconCamera width="26" height="23" />
                </div>
              </li>
              <li>
                <div className="game-images-image">
                  <IconCamera width="26" height="23" />
                </div>
              </li>
              <li>
                <div className="game-images-image">
                  <IconCamera width="26" height="23" />
                </div>
              </li>
            </ul>
            <div className="button-camera button-camera-sample">
              <div className="button-camera-inner">
                <div className="button-camera-camera">
                  <IconCamera width="26" height="23" />
                </div>
              </div>
              <div className="button-camera-inner-shadow"></div>
            </div>
        </div>
        <div className="tooltip tooltip-bottom instructions-images">
          <h2>{t("Sinu pildid")}</h2>
          {t("Tee 3 pilti ja saada need e-posti aadressile.")}
        </div>
        <div className="tooltip instructions-photo">
          <h2>{t("Tee pilt")}</h2>
          {t("Pildi tegemiseks vajuta nuppu ja oota 3 sekundit.")}
        </div>
        <div className="tooltip instructions-filters">
          <h2>{t("Filtrite valik")}</h2>
          {t("Vali filter vastavast tulbast.")}
        </div>
        <div className="tooltip instructions-players">
          <h2>{t("Mängijad")}</h2>
          {t("Mängida saab üksi või kahekesi. Mängija 2 tuvastatakse kaamera vaateväljas automaatselt.")}
        </div>
      </div>
      <div className="instructions-right">
        <Link to="/game" className="button button-primary button-large">
          {t("Alusta mängu")}
          <span>
            <IconArrow width="24" height="24" />
          </span>
        </Link>
      </div>
    </div>
  );
}
