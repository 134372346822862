import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconArrow } from "./../images/icon-arrow.svg";
import { ReactComponent as IconClose } from "./../images/icon-close.svg";

export default function CloseModal() {
  const { t } = useTranslation();
  /* {t("Welcome to React")} */
  return (
    <div className="close overlay">
      <div className="close-left">
        <div className="close-left-inner">
          <div className="close-left-text">
            <h1>{t("Kas soovid mängu sulgeda?")}</h1>
            <Link className="button button-secondary" to="/">
              {t("Sulge mäng")}
              <span>
                <IconClose width="24" height="24" />
              </span>
            </Link>
            <Link className="button button-primary" to="/game">
              {t("Tagasi mängu")}
              <span>
                <IconArrow width="24" height="24" />
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="close-right"></div>
    </div>
  );
}
