import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconArrow } from "./../images/icon-arrow.svg";
import { ReactComponent as IconClose } from "./../images/icon-close.svg";
import { ReactComponent as IconCamera } from "./../images/icon-camera.svg";
import { ReactComponent as IconUndo } from "./../images/icon-undo.svg";

import { useState } from "react";

export default function PreviewModal({ photos, removePhoto }) {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [photoStatus, setPhotoStatus] = useState([true, true, true]);

  const togglePhoto = (photoId) => {
    const newStatus = [...photoStatus];
    newStatus[photoId] = !newStatus[photoId];
    setPhotoStatus(newStatus);
  };

  const removeDeactivatedPhotos = () => {
    const removedPhotos = [];
    if (!photoStatus[0]) {
      removedPhotos.push(0);
    }
    if (!photoStatus[1]) {
      removedPhotos.push(1);
    }
    if (!photoStatus[2]) {
      removedPhotos.push(2);
    }
    if (removedPhotos.length > 0) {
      removePhoto(removedPhotos);
    }
  };

  return (
    <div className="preview overlay">
      <div className="preview-left">
        <div className="preview-left-inner">
          <div className="preview-left-text">
            <h1>{t("Sinu tehtud pildid:")}</h1>
          </div>
          <ul className="preview-images">
            <li>
              {photos.length > 0 && (
                <button
                  className={`preview-image-delete ${
                    !photoStatus[0] && `inactive`
                  }`}
                  onClick={() => togglePhoto(0)}
                >
                  {photoStatus[0] && <IconClose width="24" height="24" />}
                  {!photoStatus[0] && <IconUndo width="24" height="24" />}
                </button>
              )}
              <div className="preview-images-image">
                {photos.length < 1 && <IconCamera width="26" height="23" />}
                {photos.length > 0 && (
                  <img
                    className={!photoStatus[0] && `disabled`}
                    src={photos[0]}
                  />
                )}
              </div>
            </li>
            <li>
              {photos.length > 1 && (
                <button
                  className={`preview-image-delete ${
                    !photoStatus[1] && `inactive`
                  }`}
                  onClick={() => togglePhoto(1)}
                >
                  {photoStatus[1] && <IconClose width="24" height="24" />}
                  {!photoStatus[1] && <IconUndo width="24" height="24" />}
                </button>
              )}
              <div className="preview-images-image">
                {photos.length < 2 && <IconCamera width="26" height="23" />}
                {photos.length > 1 && (
                  <img
                    className={!photoStatus[1] && `disabled`}
                    src={photos[1]}
                  />
                )}
              </div>
            </li>
            <li>
              {photos.length > 2 && (
                <button
                  className={`preview-image-delete ${
                    !photoStatus[2] && `inactive`
                  }`}
                  onClick={() => togglePhoto(2)}
                >
                  {photoStatus[2] && <IconClose width="24" height="24" />}
                  {!photoStatus[2] && <IconUndo width="24" height="24" />}
                </button>
              )}
              <div className="preview-images-image">
                {photos.length < 3 && <IconCamera width="26" height="23" />}
                {photos.length > 2 && (
                  <img
                    className={!photoStatus[2] && `disabled`}
                    src={photos[2]}
                  />
                )}
              </div>
            </li>
          </ul>
          <button
            className={`button button-secondary ${
              photoStatus[0] && photoStatus[1] && photoStatus[2] ? "disabled" : ''
            }`}
            onClick={(e) => {
              e.preventDefault();
              removeDeactivatedPhotos();
              navigate("/game", { replace: true });
            }}
          >
            {t("Tagasi mängu")}
            <span>
              <IconClose width="24" height="24" />
            </span>
          </button>
          <button
            className={`button button-primary ${
              photos.length < 0 || (!photoStatus[0] && !photoStatus[1] && !photoStatus[2]) ? "disabled" : ''
            }`}
         
            onClick={(e) => {
              e.preventDefault();
              removeDeactivatedPhotos();
              navigate("/email", { replace: true });
            }}
          >
            {t("Saada e-posti aadressile")}
            <span>
              <IconArrow width="24" height="24" />
            </span>
          </button>
        </div>
      </div>
      <div className="preview-right"></div>
    </div>
  );
}
