import I18next from "i18next"; 
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "./../images/logo.svg";
import { ReactComponent as IconHome } from "./../images/icon-home.svg";
import { ReactComponent as IconCamera } from "./../images/icon-camera.svg";
import { ReactComponent as IconBackspace } from "./../images/icon-backspace.svg";
import { ReactComponent as IconEmail } from "./../images/icon-email.svg";
import { ReactComponent as IconLoading } from "./../images/icon-loading.svg";

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (re.test(email)) {
    return true;
  }

  return false;
}

export default function Email({ photos }) {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [isSent, setIsSent] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [email, setEmail] = useState("");
  const [closeSeconds, setCloseSeconds] = useState(-1);
  const emptyEmail = t("Sisesta e-posti aadress");

  const sendEmail = (e) => {
    setIsSending(true);
    const postData = {
      email: email,
      language: I18next.language
    };
    if (photos.length > 0) {
      postData.image1 = photos[0];
    }
    if (photos.length > 1) {
      postData.image2 = photos[1];
    }
    if (photos.length > 2) {
      postData.image3 = photos[2];
    }
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    };
    fetch(process.env.PUBLIC_URL + "/mail.php", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.sent == true) {
          setIsSent(true);
          setIsSending(false);
          setCloseSeconds(5);
        } else {
          setIsSending(false);
          alert(data.message);
        }
      });
  };

  const backspace = () => {
    const emailValue = email.split("");
    emailValue.splice(email.split("").length - 1, 1);
    setEmail(emailValue.join(""));
  };

  const keypress = (e) => {
    setEmail(email + e.target.innerText);
  };

  useEffect(() => {
    if (closeSeconds > 0) {
      setTimeout(() => {
        setCloseSeconds(closeSeconds - 1);
      }, 1000);
    }
    if (closeSeconds === 0) {
      navigate("/", { replace: true });
      setCloseSeconds(-1);
    }
  }, [closeSeconds]);

  return (
    <div className="email overlay">
      <div className="email-top">
        <Logo className="email-logo" width="119" />
        <Link className="button-home" to="/">
          <IconHome width="24" height="24" />
        </Link>
        {!isSent && (
          <ul className="email-images">
            {photos.length > 0 && (
              <li>
                <div className="email-images-image">
                  <img src={photos[0]} />
                </div>
              </li>
            )}
            {photos.length > 1 && (
              <li>
                <div className="email-images-image">
                  <img src={photos[1]} />
                </div>
              </li>
            )}
            {photos.length > 2 && (
              <li>
                <div className="email-images-image">
                  <img src={photos[2]} />
                </div>
              </li>
            )}
          </ul>
        )}
        {isSent && (
          <div className="email-sent">
            <IconEmail width="59" />
            {t("Sinu pildid on saadetud")}
          </div>
        )}
      </div>
      <div className="email-bottom">
        <div className={`email-sending ${isSending && `is-visible`}`}>
          <div>
            {t("Saatmine")}
            <span>
              <IconLoading />
            </span>
          </div>
        </div>
        {!isSent && (
          <div className={isSending && `disabled`}>
            <div className="email-input">
              {email.length > 0 ? email : emptyEmail}
              {email.length > 0 && (
                <button
                  className="button-backspace"
                  onClick={() => backspace()}
                >
                  <IconBackspace width="43" height="32" />
                </button>
              )}
            </div>
            <div className="keyboard">
              <div className="keyboard-row">
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  1
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  2
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  3
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  4
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  5
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  6
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  7
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  8
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  9
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  0
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  _
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  .
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  @
                </button>
              </div>
              <div className="keyboard-row">
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  q
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  w
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  e
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  r
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  t
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  y
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  u
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  i
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  o
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  p
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  ü
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  õ
                </button>
              </div>
              <div className="keyboard-row">
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  a
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  s
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  d
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  f
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  g
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  h
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  j
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  k
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  l
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  ö
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  ä
                </button>
              </div>
              <div className="keyboard-row">
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  z
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  x
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  c
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  v
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  b
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  n
                </button>
                <button
                  className="keyboard-button"
                  onClick={(e) => keypress(e)}
                >
                  m
                </button>
              </div>
            </div>
            <button
              onClick={() => {
                sendEmail();
              }}
              className={`button button-primary ${
                email.length === 0 || !validateEmail(email) || isSending ? "disabled" : ""
              }`}
            >
              {t("Saada")}
              <span>
                <IconEmail width="29" height="16" />
              </span>
            </button>
          </div>
        )}
        {isSent && (
          <div className="email-closing">
            {t("Sulgen")}
            <div className="button-camera">
              <svg
                style={{ display: "block" }}
                height="178"
                width="178"
                className="button-camera-circle"
              >
                <circle
                cx="89"
                cy="89"
                r="84"
                stroke="#F15A2A"
                strokeWidth="11"
                fill="#dadfe8"
                strokeDasharray={527}
                strokeDashoffset={closeSeconds * 105 + 2}
              />
              </svg>
              <div className="button-camera-inner">
                <div className="button-camera-counter">{closeSeconds}</div>
              </div>
              <div className="button-camera-inner-shadow"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
